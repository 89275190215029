import React from 'react';

export interface UseProvideAuthProps {
  authorizeUrl: string;
  deAuthorizeUrl: string;
  tokenUrl: string;
  userInfoUrl: string;
  clientId: string;
  redirectUrl: string;
  service: NiamService | undefined;
  scopes?: string[];
  permissions?: string[];
}

export enum NiamService {
  'AADLogin'= 'AADLogin',
  'LoginWithEID' = 'LoginWithEID',
  'UCLogin' = 'UCLogin',
  'EnentoLogin' = 'EnentoLogin',
  'UCPasswordLogin' = 'UCPasswordLogin',
  'UCBankIDLogin' = 'UCBankIDLogin'
}

export type AuthProviderProps = {
  children?: React.ReactNode;
} & UseProvideAuthProps;

export interface UserPermission {
  name: string;
}

export interface User {
  sub: string,
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  permissions: UserPermission[];
}

export interface UseAuth {
  authenticated: boolean;
  user: User | null;
  accessToken: string | null,
  error: string | null,
  idToken: string | null,
  resetLogin: (error?: string) => void,
  login: (state?: NiamService) => Promise<void>,
  logout: () => Promise<void>,
  hasPermission: (permission: string) => boolean,
}

export interface AccessTokenResponse {
  access_token: string,
  id_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
  refresh_token: string;
}

export interface UserInfoPermissionResponse {
  name: string;
}

export interface UserInfoResponse {
  permissions: any[],
  given_name: string;
  family_name: string;
  name: string,
  email: string
  sub: string;
}
