import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import {SessionStatus} from "./SessionStatus";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {CollectorSessionBrief, Insights} from "../../../client/AccountInsightsTypes";
import {useAuth} from "../../../nux/niam/auth";
import {useAccountInsightClient} from "../../../client/AccountInsightClient";
import Box from '@mui/material/Box';

interface ConsentListItemProps {
    row: CollectorSessionBrief
    onShowDetails: (id: string) => void
    onRestart: (id: string) => void
    onCopyConsentUrlToClipboard: (clientRedirectUrl: string) => void
    onRemoveSession: (id: string) => void
    onArchiveSession: (id: string) => void
    onUnarchiveSession: (id: string) => void
}

class ExtendedInformation {
    history: string[]
    created: string;
    calculatedIncome: string;
    calculatedIncomePeriod: string;
    isArchived: boolean;
    archivedAt: string;
    initiatorId: string;
    initiatorName: string;

    constructor(history: string[],
                created: string,
                calculatedIncome: string,
                calculatedIncomePeriod: string,
                isArchived: boolean,
                archivedAt: string,
                initiatorId: string,
                initiatorName: string) {
        this.history = history;
        this.created = created;
        this.calculatedIncome = calculatedIncome;
        this.calculatedIncomePeriod = calculatedIncomePeriod;
        this.isArchived = isArchived;
        this.archivedAt = archivedAt;
        this.initiatorId = initiatorId;
        this.initiatorName = initiatorName;
    }
}

export const ConsentListItem = ({
                                    row,
                                    onShowDetails,
                                    onRestart,
                                    onCopyConsentUrlToClipboard,
                                    onRemoveSession,
                                    onArchiveSession,
                                    onUnarchiveSession
                                }: ConsentListItemProps) => {
    const {t} = useTranslation()
    const {accessToken, authenticated} = useAuth()
    const {getAccountInsight} = useAccountInsightClient()
    const [extended, setExtended] = useState<boolean>(false)
    const [extendedInformation, setExtendedInformation] = useState<ExtendedInformation | undefined>(undefined)

    const extendPanel = async () => {
        setExtended(true)
        try {
            let collectorSession = await getAccountInsight(row.id);
            setExtendedInformation(new ExtendedInformation(
                collectorSession.history.map(history => {
                    return history.split(' ').map(value => {
                        let statusStr = statusToString(value);
                        if (statusStr !== value)
                            return t(statusStr)
                        return value
                    }).join(' ')
                }),
                collectorSession.created,
                collectorSession.calculatedIncome,
                collectorSession.calculatedIncomePeriod,
                collectorSession.isArchived,
                collectorSession.archivedAt,
                collectorSession.initiatorId,
                collectorSession.initiatorName
            ))
        } catch (e) {
            console.error("Failed to get account insights for " + row.id, e)
        }
    }

    const implodePanel = async () => {
        setExtended(false)
        setExtendedInformation(undefined);
    }

    const HistoryComponent = extendedInformation ?
        <>
            {extendedInformation.history.map(hist => {
                return (<Typography key={hist}>{hist}<br/></Typography>)
            })
            }
        </>
        : <CircularProgress/>

    const CalculatedIncomeComponent = extendedInformation ?
        <>
            {extendedInformation.calculatedIncome}
        </>
        : <CircularProgress/>

    const CalculatedIncomePeriodComponent = extendedInformation ?
        <>
            {extendedInformation.calculatedIncomePeriod}
        </>
        : <CircularProgress/>

    return (
        <>
            <ListItem
                sx={{marginTop: '5px', borderRadius: '5px', backgroundColor: 'white'}}
                alignItems="flex-start"
                secondaryAction={
                    <>
                        {row.isArchived ?
                            <>
                                {t('ARCHIVED')}
                            </>
                            : row.completed === "true" ?
                                <>
                                    <Button
                                        disabled={row.status !== 'INSIGHTS_COLLECTED'}
                                        onClick={event => onShowDetails(row.id)}>
                                        {t('showInsightsButtonCaption')}
                                    </Button>
                                </>
                                : row.status === "INITIALIZING" || row.status === "WAITING_FOR_CONSENT" ?
                                    <Button
                                        className='m-1'
                                        disabled={!row.clientRedirectUrl}
                                        onClick={event => onCopyConsentUrlToClipboard(row.clientRedirectUrl)}>
                                        {t('copyConsentToClipboardButtonCaption')}
                                    </Button>
                                    : null
                        }

                        {extended ? (
                            <IconButton
                                edge="end"
                                aria-label="implode"
                                data-testid="do-click-insight-session-implode"
                                onClick={() => implodePanel()}>
                                <ExpandLess/>
                            </IconButton>
                        ) : (
                            <IconButton
                                edge="end"
                                aria-label="extend"
                                data-testid="do-click-insight-session-extend"
                                onClick={() => extendPanel()}>
                                <ExpandMore/>
                            </IconButton>
                        )}
                    </>
                }
            >
                <ListItemAvatar>
                    <PersonIcon/>
                </ListItemAvatar>
                <ListItemText
                    primary={row.name}
                    secondary={row.personId}
                />
                <ListItemText
                    primary={`${t(statusToString(row.status))}`}
                    secondary={<SessionStatus status={row.status}/>}
                />
            </ListItem>
            <Collapse in={extended} timeout="auto" unmountOnExit>
                <Grid container spacing={0} style={{
                    background: "white",
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                }}>
                    <Grid item xs={12} sm={4} style={{paddingRight: '10px'}}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="overline">
                                    {t('internalId')}
                                </Typography>
                                <Typography variant="body1">
                                    {row.id}
                                </Typography>
                                <Typography variant="overline">
                                    {t('created')}
                                </Typography>
                                <Typography variant="body1">
                                    {extendedInformation?.created}
                                </Typography>
                                {extendedInformation?.initiatorName && (
                                    <>
                                        <Typography variant="overline">
                                            {t('createdBy')}
                                        </Typography>
                                        <Typography variant="body1">
                                            {extendedInformation?.initiatorName}
                                        </Typography>
                                    </>
                                )}
                                {extendedInformation?.isArchived && (
                                    <>
                                        <Typography variant="overline">
                                            {t('ARCHIVED')}
                                        </Typography>
                                        <Typography variant="body1">
                                            {extendedInformation?.archivedAt}
                                        </Typography>
                                    </>
                                )}
                                {row.email && (
                                    <>
                                        <Typography variant="overline">
                                            {t('personEmail')}
                                        </Typography>
                                        <Typography variant="body1">
                                            {row.email}
                                        </Typography>
                                    </>
                                )}
                                {row.errorMessage && (
                                    <>
                                        <Typography variant="overline">
                                            Error Message
                                        </Typography>
                                        <Typography variant="body1">
                                            {row.errorMessage}
                                        </Typography>
                                    </>
                                )}

                                <Box mt={4} />

                                <Box display="flex" flexDirection="column" gap={2}>
                                {!extendedInformation?.isArchived && (
                                    <>
                                        <Button
                                            variant="outlined"
                                            startIcon={<ArchiveIcon />}
                                            onClick={() => onArchiveSession(row.id)}
                                            sx={{ width: '200px' }}
                                        >
                                            {t('archive')}
                                        </Button>
                                    </>
                                )}
                                {extendedInformation?.isArchived && (
                                    <>
                                        <Button
                                            variant="outlined"
                                            startIcon={<UnarchiveIcon />}
                                            onClick={() => onUnarchiveSession(row.id)}
                                            sx={{ width: '200px' }}
                                        >
                                            {t('unarchive')}
                                        </Button>
                                    </>
                                )}

                                <Button
                                    variant="outlined"
                                    startIcon={<RestartAltIcon />}
                                    disabled={row.completed !== "true" && row.status !== 'ERROR'}
                                    onClick={() => onRestart(row.id)}
                                    sx={{ width: '200px' }}
                                >
                                    {t('restartButtonCaption')}
                                </Button>

                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => onRemoveSession(row.id)}
                                    data-testid="do-click-insight-session-remove"
                                    sx={{ width: '200px' }}
                                >
                                    {t('delete')}
                                </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{paddingRight: '10px'}}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="overline">
                                    {t('calculatedIncome')}
                                </Typography>
                                <Typography variant="body1">
                                    {CalculatedIncomeComponent}
                                </Typography>
                                <Typography variant="overline">
                                    {t('calculatedIncomePeriod')}
                                </Typography>
                                <Typography variant="body1">
                                    {CalculatedIncomePeriodComponent}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="overline">
                                    {t('history')}
                                </Typography>
                                <Typography variant="body1">
                                    {HistoryComponent}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Collapse>
        </>
    )
}

function calculateIcome(insights: Insights): number {
    console.log("calculateIcome", JSON.stringify(insights))
    try {
        return 0;
        /*return insights.categorisation.INCOME.subCategories
            .filter(sub => sub.name==='income')
            .map(sub => )
            .posSum*/
    } catch (e) {
        console.log("calculateIcome", JSON.stringify(insights), e)
        return 0;
    }
}

const statusToString = (status: string) => {
    switch (status) {
        case "INITIALIZING":
            return "initializing";
        case "INSIGHTS_COLLECTED":
            return "completed";
        case "FETCHING_INSIGHTS":
            return "fetchingInsights"
        case "COLLECTION_IN_PROGRESS":
            return "collectingAndCalculating";
        case "WAITING_FOR_CONSENT":
            return "waitingForConsent";
        case "ERROR":
            return "failed";
        case "CREATED":
            return "historyCreated";
        case "EMAIL_SENT":
            return "emailSent";
        case "CONSENT_LINK_OPENED":
            return "consentLinkOpened";
        case "CUSTOMER_APPROVED_CONSENT":
            return "customerApprovedConsent";
        case "CATEGORIZATION_STARTED":
            return "categorizationStarted";
        case "CATEGORIZATION_DONE":
            return "categorizationDone";
        case "INSIGHTS_STARTED":
            return "insightsStarted";
        case "CUSTOMER_DENIED_CONSENT":
            return "customerDeniedConsent";
        case "CUSTOMER_DENIED_CONSENT_RESTART":
            return "customerDeniedConsentRestart";
        case "CONSENT_RESTART":
            return "consentRestart";
        case "COMPLETED":
            return "processCompleted";

        default:
            return status;
    }
}


