import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import MenuItem from '@mui/material/MenuItem';

export enum Language {
  EN = 'en',
  SV = 'sv',
  FI = 'fi',
  NO = 'no',
  DK = 'dk'
}

export interface NuxLanguageSelectorProps {
  languages: Language[];
}

const NuxLanguageSelector = ({ languages }: NuxLanguageSelectorProps) => {
  const { t, i18n } = useTranslation();

  const currentLanguage = toLang(i18n.language);

  if (!languages || languages.length == 0)
    return null;

  if (languages.length == 0)
    return (
      <Typography variant="body2">{toCode(languages[0])}</Typography>
    );

  if (languages.length == 2)
    return (
      <>
        <LanguageIcon fontSize="small" color="action" />
        {languages.map(language => (
          <Button
            key={language}
            disabled={i18n.language.startsWith(language)}
            onClick={() => i18n.changeLanguage(language)}
            size="small">
            {toCode(language)}
          </Button>
        ))}
      </>
    );

  return (
    <>
      <LanguageIcon fontSize="small" color="action" />
      <Select
        size="small"
        variant="standard"
        disableUnderline
        value={currentLanguage.toLowerCase()}
        /*
                        IconComponent={_props => {
                            const rotate = _props.className.toString().includes("iconOpen");
                            return rotate
                                ? (<ExpandLess fontSize="small"/>)
                                : (<ExpandMore fontSize="small"/>)
                            }}
        */
        onChange={event => i18n.changeLanguage(event.target.value)}
      >
        {languages.map(language => (
          <MenuItem
            key={language}
            selected={currentLanguage === language}
            value={language}>
            {toCode(language)}
          </MenuItem>
        ))}
      </Select>
    </>
  );

};

function toCode(language: Language) {
  switch (language) {
    case Language.SV:
      return 'Svenska';
    case Language.EN:
      return 'English';
    case Language.FI:
      return 'Suomi';
    case Language.NO:
      return 'Norsk';
    case Language.DK:
      return 'Dansk';
  }
}

function toLang(language: string) {
  if (!language)
    return Language.EN;
  const ln = language.substring(0, 2);
  for (const lang in Language) {
    if (ln.localeCompare(lang, undefined, { sensitivity: 'accent' }) === 0)
      return lang;
  }
  console.error('Language not found \'' + language + '\'');
  return Language.EN;
}


export default NuxLanguageSelector;
