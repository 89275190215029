import React, {ReactElement, ReactNode} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {useAuth} from "../../niam/auth";
import {useTranslation} from "react-i18next";
import {Badge, Link} from "@mui/material";
import Config from "../../../config/config";
import NuxLanguageSelector, {Language} from "../buttons/NuxLanguageSelector";
import NuxUser from "../buttons/NuxUser";

export interface NuxTopNavProps {
    home: ReactNode
    languages: Language[];
    env?: string
    menuItems: ReactElement<typeof MenuItem>[];
}

const NuxTopNav = ({home, env, languages, menuItems}: NuxTopNavProps) => {

    const {t} = useTranslation(['nux'])

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const {authenticated, login, logout} = useAuth()

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const HomeClickable = (
        <Link href="/" style={{ textDecoration: 'none' }}>
            {home}
        </Link>
    )

    return (
        <>
            <AppBar position="static" style={{background: "white"}}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {env && (
                            <Tooltip title={"Active environment: " + env}>
                                <Badge
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={env}
                                    color="secondary">
                                    {home}
                                </Badge>
                            </Tooltip>
                        )}
                        {!env && HomeClickable}
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                            </Menu>
                        </Box>
                        <AdbIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            LOGO
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        </Box>
                        <Box>
                            <NuxLanguageSelector languages={languages}/>
                        </Box>
                        <Box sx={{ ml: 2}}>
                            <NuxUser/>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}

export default NuxTopNav;

