import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  Alert, Card, CardActions, CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab, Stack,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import NuxButton from '../../../nux/components/buttons/NuxButton';
import { Spinner } from 'react-bootstrap';
import ProgressSuccess from './ProgressSuccess';
import Container from '@mui/material/Container';

interface CreatingNewAccountInsightSessionDialogProps {
  consentUrl: string | null;
  message: string;
  show: boolean;

  close(): void;

  onCopyConsentUrlToClipboard: (clientRedirectUrl: string) => void;
}

export const CreatingNewAccountInsightSessionDialog = ({
                                                         consentUrl,
                                                         message,
                                                         show,
                                                         close,
                                                         onCopyConsentUrlToClipboard,
                                                       }: CreatingNewAccountInsightSessionDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={close} open={show}>
      <DialogTitle>{t('Skapar förfrågan')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!consentUrl
            ? t('Förfrågan under skapande')
            : t('Skapad! Du kan nu kopiera url:en')
          }
        </DialogContentText>
        {message && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            {message}
          </Alert>
        )}
        <Stack direction="row" spacing={1} alignItems="center">
          <ProgressSuccess
            success={!!consentUrl}
            loading={!consentUrl}
          />
          <NuxButton
            data-testid="do-create-insight-session-request"
            disabled={!consentUrl}
            color="success"
            onClick={() => onCopyConsentUrlToClipboard(consentUrl ? consentUrl : '')}
            variant="contained">
            {t('copyConsentToClipboardButtonCaption')}
          </NuxButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <NuxButton
          data-testid="cancel-create-insight-session-request"
          onClick={close}
          color="secondary"
          variant="outlined">
          {t('dialogClose')}
        </NuxButton>
      </DialogActions>

    </Dialog>
  );
};
